<template>
<div>

    <b-row>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-edit fa-md"></i> <span class="h5"> Evaluación de vulnerabilidades</span>
                </CCardHeader>
                <CCardBody>
                    <validation-observer ref="observer" v-slot="{ handleSubmit }">
                        <b-form @submit.stop.prevent="handleSubmit(registrarEvaluacion)">
                            <b-col md="12" class="mt-3">
                                <template>
                                    <div class="accordion" role="tablist" v-for="(activo, k) in datosAcordionActivos" :key="k">
                                        <b-card no-body class="mb-1">
                                            <b-card-header header-tag="header" class="p-1 bg-accordion" role="tab">
                                                <div block v-b-toggle="'accordion-'+activo.idActivo" class="bg-accordion">
                                                    <span class=" h6"> {{activo.activo}}</span>
                                                    <span class="float-right"> <i class="fas" style="font-size:1.1rem"></i></span>
                                                </div>
                                            </b-card-header>
                                            <b-collapse :id="'accordion-'+activo.idActivo" visible accordion="my-accordion" role="tabpanel">
                                                <b-card-body>
                                                    <b-row>
                                                        <b-col md="12" class="mt-2">
                                                            <b-table :items="datosAcordionActivos[k].listaVulnerabilidades.filter(x => x.estado == '2')" :fields="cabeceraVulnerabilidades" class="table-custom" mediun responsive outlined fixed>
                                                                <template v-slot:cell(vulnerabilidades)="row">
                                                                    <b-tabs content-class="mt-3" active-nav-item-class="font-weight-bold text-uppercase text-dark">
                                                                        <b-tab title="Datos generales" active>
                                                                            <b-row>
                                                                                <b-col md="2">
                                                                                    <validation-provider :name="'número '+row.index" :rules="{required: true}" v-slot="validationContext">
                                                                                        <b-form-group label="Número:" class="mb-2">
                                                                                            <b-form-input type="text" :state="getValidationState(validationContext)" placeholder="Ingrese el número" v-model="row.item.numero"></b-form-input>
                                                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                                        </b-form-group>
                                                                                    </validation-provider>
                                                                                </b-col>
                                                                                <b-col md="3">
                                                                                    <validation-provider :name="'tipo '+row.index" :rules="{required: true}" v-slot="validationContext">
                                                                                        <b-form-group label="Tipo:" class="mb-2">
                                                                                            <b-form-input type="text" :state="getValidationState(validationContext)" placeholder="Ingrese el tipo" v-model="row.item.tipo"></b-form-input>
                                                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                                        </b-form-group>
                                                                                    </validation-provider>
                                                                                </b-col>
                                                                                <b-col md="5">
                                                                                    <validation-provider :name="'vulnerabilidad '+row.index" :rules="{required: true}" v-slot="validationContext">
                                                                                        <b-form-group label="Vulnerabilidad:" class="mb-2">
                                                                                            <b-form-input type="text" :state="getValidationState(validationContext)" placeholder="Ingrese la vulnerabilidad" v-model="row.item.vulnerabilidad"></b-form-input>
                                                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                                        </b-form-group>
                                                                                    </validation-provider>
                                                                                </b-col>
                                                                                <b-col md="2" class="text-right mt-3">
                                                                                    <b-button @click="quitarVulnerabilidad(row, k)" v-if="datosAcordionActivos[k].listaVulnerabilidades.filter(x => x.estado == '2').length >= 2" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                                                        <i class="fas fa-trash-alt  my-0 mx-0 mr-1"></i> Eliminar vulnerabilidad
                                                                                    </b-button>
                                                                                </b-col>
                                                                                <b-col md="4">
                                                                                    <validation-provider :name="'riesgo asociado al control '+row.index" :rules="{required: true}" v-slot="validationContext">
                                                                                        <b-form-group label="Riesgo asociado al control:" class="mb-2">
                                                                                            <b-form-input type="text" :state="getValidationState(validationContext)" placeholder="Ingrese el riesgo asociado al control" v-model="row.item.riesgoAsociado"></b-form-input>
                                                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                                        </b-form-group>
                                                                                    </validation-provider>
                                                                                </b-col>
                                                                                <b-col md="4">
                                                                                    <validation-provider :name="'propietario del riesgo'+row.index" :rules="{required: true}" v-slot="validationContext">
                                                                                        <b-form-group label="Propietario del riesgo:" class="mb-2">
                                                                                            <b-form-input type="text" :state="getValidationState(validationContext)" placeholder="Ingrese el propietario del riesgo" v-model="row.item.propietarioRiesgo"></b-form-input>
                                                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                                        </b-form-group>
                                                                                    </validation-provider>
                                                                                </b-col>
                                                                                <b-col md="4">
                                                                                    <validation-provider :name="'gestor del riesgo '+row.index" :rules="{required: true}" v-slot="validationContext">
                                                                                        <b-form-group label="Gestor del riesgo:" class="mb-2">
                                                                                            <b-form-input type="text" :state="getValidationState(validationContext)" placeholder="Ingrese el gestor del riesgo" v-model="row.item.gestorRiesgo"></b-form-input>
                                                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                                        </b-form-group>
                                                                                    </validation-provider>
                                                                                </b-col>
                                                                                <b-col md="4">
                                                                                    <validation-provider name="'probabilidad '+row.index" :rules="{required: true}" v-slot="validationContext">
                                                                                        <b-form-group label="Probabilidad:" class="mb-2">
                                                                                            <b-form-select :options="comboValorActivo" @change="calcularNivelRiesgo(k, row)" v-model="row.item.probabilidad" :state="getValidationState(validationContext)">
                                                                                                <template v-slot:first>
                                                                                                    <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                                                                </template>
                                                                                            </b-form-select>
                                                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                                        </b-form-group>
                                                                                    </validation-provider>
                                                                                </b-col>
                                                                                <b-col md="4">
                                                                                    <validation-provider :name="'impacto '+row.index" :rules="{required: true}" v-slot="validationContext">
                                                                                        <b-form-group label="Impacto:" class="mb-2">
                                                                                            <b-form-select :options="comboValorActivo" @change="calcularNivelRiesgo(k, row)" v-model="row.item.impacto" :state="getValidationState(validationContext)">
                                                                                                <template v-slot:first>
                                                                                                    <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                                                                </template>
                                                                                            </b-form-select>
                                                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                                        </b-form-group>
                                                                                    </validation-provider>
                                                                                </b-col>
                                                                                <b-col md="4">
                                                                                    <validation-provider :name="'escala '+row.index" :rules="{required: true}" v-slot="validationContext">
                                                                                        <b-form-group label="Escala:" class="mb-2">
                                                                                            <b-form-input type="text" :state="getValidationState(validationContext)" placeholder="Ingrese la escala" v-model="row.item.escala"></b-form-input>
                                                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                                        </b-form-group>
                                                                                    </validation-provider>
                                                                                </b-col>
                                                                                <b-col md="4">
                                                                                    <validation-provider :name="'nivel de riesgo '+row.index" :rules="{required: true}" v-slot="validationContext">
                                                                                        <b-form-group label="Nivel de riesgo:" class="mb-2">
                                                                                            <b-form-input disabled :class="row.item.nivelRiesgo==2 ? 'disabled-success' : row.item.nivelRiesgo>=3 && row.item.nivelRiesgo<=4 ? 'disabled-warning' : row.item.nivelRiesgo>=5 ? 'disabled-danger' : 'disabled-white'" :state="getValidationState(validationContext)" placeholder="Ingrese el nivel de riesgo" v-model="row.item.nivelRiesgoTexto"></b-form-input>
                                                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                                        </b-form-group>
                                                                                    </validation-provider>
                                                                                </b-col>
                                                                                <b-col md="12">
                                                                                    <validation-provider :name="'descripción del riesgo '+row.index" v-slot="validationContext">
                                                                                        <b-form-group label="Descripción del riesgo:" class="mb-2">
                                                                                            <b-form-textarea rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese la descripción del riesgo" v-model="row.item.descripcionRiesgo"></b-form-textarea>
                                                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                                        </b-form-group>
                                                                                    </validation-provider>
                                                                                </b-col>
                                                                                <b-col md="12" class="mt-2">
                                                                                    <span class="h6 text-muted">Controles</span>
                                                                                    <CButton size="sm" class="float-right" color="dark" @click="agregarControl(row, k)">
                                                                                        <i class="fas fa-plus fa-sm"></i> Agregar control
                                                                                    </CButton>
                                                                                </b-col>
                                                                                <b-col md="12" class="mt-2">
                                                                                    <b-table :items="row.item.listaControles.filter(x => x.estado == '2')" :fields="campoControles" class="table-custom" fixed responsive show-empty mediun outlined hover empty-text="Aún no hay datos en esta sección.">
                                                                                        <template v-slot:cell(tipoControl)="rowControl">
                                                                                            <b-form-select :options="comboTipoControl" v-model="rowControl.item.tipoControl">
                                                                                                <template v-slot:first>
                                                                                                    <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                                                                </template>
                                                                                            </b-form-select>
                                                                                        </template>
                                                                                        <template v-slot:cell(definicion)="rowControl">
                                                                                            <b-form-input type="text" v-model="rowControl.item.definicion" placeholder="Ingrese definición"></b-form-input>
                                                                                        </template>
                                                                                        <template v-slot:cell(fechaLimite)="rowControl">
                                                                                            <b-form-input type="date" v-model="rowControl.item.fechaLimite"></b-form-input>
                                                                                        </template>
                                                                                        <template v-slot:cell(idResponsable)="rowControl">
                                                                                            <v-select :reduce="comboCargoResponsable =>comboCargoResponsable.idPuestoTrabajo" label="puestoTrabajo" placeholder="Seleccione una opción" v-model="rowControl.item.idResponsable" :options="comboCargoResponsable">
                                                                                                <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Puestos de trabajo'}"> Registra aquí</router-link></span>
                                                                                            </v-select>
                                                                                        </template>
                                                                                        <template v-slot:cell(recursos)="rowControl">
                                                                                            <b-form-input type="text" v-model="rowControl.item.recursos" placeholder="Ingrese recursos"></b-form-input>
                                                                                        </template>
                                                                                        <template v-slot:cell(conseguido)="rowControl">
                                                                                            <b-form-checkbox plain :id="'checkbox-'+k+'-'+row.index+'-'+rowControl.index" v-model="rowControl.item.conseguido" :name="'checkbox-'+rowControl.index" value="2" unchecked-value="1"></b-form-checkbox>
                                                                                        </template>
                                                                                        <template #cell(acciones)="param">
                                                                                            <b-button @click="quitarControl(param, row, k)" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                                                                <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                                                            </b-button>
                                                                                        </template>
                                                                                    </b-table>
                                                                                </b-col>

                                                                            </b-row>
                                                                        </b-tab>
                                                                        <b-tab title="Nueva evaluación de riesgos" v-if="datosNuevaEvaluacion.idEvaluacion!=''">
                                                                            <b-col md="12">
                                                                                <b-row>
                                                                                    <b-col md="4">
                                                                                        <validation-provider :name="'probabilidad '+row.index" :rules="{required: true}" v-slot="validationContext">
                                                                                            <b-form-group label="Probabilidad:" class="mb-2">
                                                                                                <b-form-select :options="comboValorActivo" @change="calcularNivelRiesgoMatriz(row)" v-model="datosNuevaEvaluacionRiesgo[row.index].probabilidad" :state="getValidationState(validationContext)">
                                                                                                    <template v-slot:first>
                                                                                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                                                                    </template>
                                                                                                </b-form-select>
                                                                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                                            </b-form-group>
                                                                                        </validation-provider>
                                                                                    </b-col>
                                                                                    <b-col md="4">
                                                                                        <validation-provider :name="'impacto ' +row.index" :rules="{required: true}" v-slot="validationContext">
                                                                                            <b-form-group label="Impacto:" class="mb-2">
                                                                                                <b-form-select :options="comboValorActivo" @change="calcularNivelRiesgoMatriz(row)" v-model="datosNuevaEvaluacionRiesgo[row.index].impacto" :state="getValidationState(validationContext)">
                                                                                                    <template v-slot:first>
                                                                                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                                                                    </template>
                                                                                                </b-form-select>
                                                                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                                            </b-form-group>
                                                                                        </validation-provider>
                                                                                    </b-col>
                                                                                    <b-col md="4">
                                                                                        <validation-provider :name="'nivel de riesgo '+row.index" :rules="{required: true}" v-slot="validationContext">
                                                                                            <b-form-group label="Nivel de riesgo:" class="mb-2">
                                                                                                <b-form-input disabled :class="datosNuevaEvaluacionRiesgo[row.index].nivelRiesgo==2 ? 'disabled-success' : datosNuevaEvaluacionRiesgo[row.index].nivelRiesgo>=3 && datosNuevaEvaluacionRiesgo[row.index].nivelRiesgo<=4 ? 'disabled-warning' : datosNuevaEvaluacionRiesgo[row.index].nivelRiesgo>=5 ? 'disabled-danger' : 'disabled-white'" :state="getValidationState(validationContext)" placeholder="Ingrese el nivel de riesgo" v-model="datosNuevaEvaluacionRiesgo[row.index].nivelRiesgoTexto"></b-form-input>
                                                                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                                            </b-form-group>
                                                                                        </validation-provider>
                                                                                    </b-col>
                                                                                </b-row>
                                                                            </b-col>
                                                                        </b-tab>
                                                                    </b-tabs>
                                                                </template>
                                                            </b-table>
                                                            <b-col md="12" class="text-center mt-2">
                                                                <CButton size="sm" color="dark" @click="agregarVulnerabilidad(k)">
                                                                    <i class="fas fa-plus fa-sm"></i> Agregar vulnerabilidad
                                                                </CButton>
                                                            </b-col>
                                                        </b-col>
                                                    </b-row>
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>
                                    </div>
                                </template>
                            </b-col>
                            <b-col class="my-2 text-center" md="12">
                                <b-button v-if="$route.params.id!=null" :to="{name: 'Selección de activos', params: {id: $route.params.id}}" variant="dark" class=" mr-2">
                                    <i class="fas fa-arrow-left"></i> Volver
                                </b-button>
                                <b-button v-if="$route.params.id==null" :to="{name: 'Selección de activos'}" variant="dark" class=" mr-2">
                                    <i class="fas fa-arrow-left"></i> Volver
                                </b-button>
                                <b-button v-if="checkPermissions('005-27001-VUL','u')==1 && $route.params.id && datosAcordionActivos.length>0" type="submit" variant="success">
                                    <i class="fas fa-save"></i> Guardar
                                </b-button>
                                <b-button v-if="checkPermissions('005-27001-VUL','c')==1 && !$route.params.id && datosAcordionActivos.length>0" type="submit" variant="success">
                                    <i class="fas fa-save"></i> Guardar
                                </b-button>

                            </b-col>
                        </b-form>
                    </validation-observer>
                </CCardBody>
            </CCard>
        </b-col>
    </b-row>
</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    components: {

    },
    data() {
        return {
            modalAgregarRiesgos: false,
            BtnMostrar: false,
            cabecera: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                }, {
                    key: "riesgo",
                    label: "Riesgo",
                    class: "text-center",
                },
                {
                    key: "selected",
                    label: "Selección",
                    class: "text-center",
                }
            ],
            cabeceraVulnerabilidades: [{
                key: 'vulnerabilidades',
                label: 'Vulnerabilidades',
            }],
            campoControles: [{
                    key: "tipoControl",
                    label: "Tipo de control",
                    class: "text-center",
                }, {
                    key: "definicion",
                    label: "Definición",
                    class: "text-center",
                },
                {
                    key: "fechaLimite",
                    label: "Fecha límite",
                    class: "text-center",
                },
                {
                    key: "idResponsable",
                    label: "Responsable",
                    class: "text-center",
                },
                {
                    key: "recursos",
                    label: "Recursos",
                    class: "text-center",
                },
                {
                    key: "conseguido",
                    label: "¿Conseguido?",
                    class: "text-center",
                },
                {
                    key: "acciones",
                    label: '',
                    class: "text-center"
                }
            ],
            listaActivos: [],
            arrayActivos: [],
            datosAcordionActivos: [],
            datosNuevaEvaluacion: {
                idEvaluacion: '',
                idCliente: '',
                ejercicio: JSON.parse(localStorage.settings).yExcs,
            },
            listaProbalidad: [{
                value: 1,
                text: 'Remota'
            }, {
                value: 2,
                text: 'Inusual'
            }, {
                value: 3,
                text: 'Ocasional'
            }, {
                value: 4,
                text: 'Frecuente'
            }],
            listaConsecuencias: [{
                value: 1,
                text: 'Moderada'
            }, {
                value: 2,
                text: 'Relevante'
            }, {
                value: 3,
                text: 'Grave'
            }, {
                value: 4,
                text: 'Catastrófica'
            }],
            comboPlazo: [{
                idPlazo: '1',
                descripcion: '6 Meses'
            }, {
                idPlazo: '2',
                descripcion: '12 Meses'
            }, {
                idPlazo: '3',
                descripcion: '18 Meses'
            }, {
                idPlazo: '4',
                descripcion: 'Otros'
            }],
            comboCargoResponsable: [],
            datosSession: {
                idCliente: ''
            },
            disabled: false,
            datosEvaluacionDetalleVulnerabilidad: [],
            datosEvaluacionDetalleControl: [],
            comboTipoControl: [{
                    value: 1,
                    text: 'Operativo'
                },
                {
                    value: 2,
                    text: 'Administrativo'
                },
                {
                    value: 3,
                    text: 'Eliminación'
                },
                {
                    value: 4,
                    text: 'Sustitución'
                },
                {
                    value: 5,
                    text: 'Contingencia'
                },
            ],
            comboValorActivo: [{
                    value: 3,
                    text: 'Alto'
                },
                {
                    value: 2,
                    text: 'Medio'
                },
                {
                    value: 1,
                    text: 'Bajo'
                }
            ],
            datosNuevaEvaluacionRiesgo: [],
        }
    },
    methods: {
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        descargarDocumento(url) {
            window.open(url)
        },
        blurPuestoTrabajo() {
            this.computedForm.refs.cargo.validate();
        },
        agregarRiesgos() {
            let me = this;
            let arrayActivos = me.$route.params.arrayActivos;
            for (const i in arrayActivos) {
                me.datosAcordionActivos.push({
                    idEvaluacionDetalleActivo: '',
                    idEvaluacion: '',
                    idActivo: arrayActivos[i].idActivo,
                    activo: arrayActivos[i].activo,
                    listaVulnerabilidades: [{
                        idEvaluacionDetalleVulnerabilidad: '',
                        idEvaluacionDetalleActivo: '',
                        numero: '',
                        tipo: '',
                        vulnerabilidad: '',
                        riesgoAsociado: '',
                        propietarioRiesgo: '',
                        gestorRiesgo: '',
                        probabilidad: null,
                        impacto: null,
                        escala: '',
                        nivelRiesgo: '',
                        nivelRiesgoTexto: '',
                        descripcionRiesgo: '',
                        listaControles: [{
                            idEvaluacionDetalleControl: '',
                            idEvaluacionDetalleVulnerabilidad: '',
                            tipoControl: null,
                            definicion: '',
                            fechaLimite: moment().format('YYYY-MM-DD'),
                            idResponsable: null,
                            recursos: '',
                            conseguido: 1,
                            estado: 2
                        }],
                        estado: 2,
                    }],
                    estado: 2,
                });
            }
        },
        consultarEvaluacionActivo() {
            let me = this;
            let arrayActivos = me.$route.params.arrayActivos;
            me.datosAcordionActivos = arrayActivos;

            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/consultar-evaluacion-activo-sgsi", {
                        params: {
                            idCliente: me.datosSession.idCliente,
                            idEvaluacion: me.$route.params.id,
                        },
                    }
                )
                .then(function (response) {
                    me.datosNuevaEvaluacion.idEvaluacion = response.data[0].idEvaluacion;
                    me.datosNuevaEvaluacion.idCliente = response.data[0].idCliente;
                    me.datosNuevaEvaluacion.ejercicio = response.data[0].ejercicio;

                    for (const j in arrayActivos) {
                        for (const i in response.data) {
                            if (arrayActivos[j].idActivo == response.data[i].idActivo) {
                                me.datosAcordionActivos.splice(j, 1, {
                                    idEvaluacionDetalleActivo: response.data[i].idEvaluacionDetalleActivo,
                                    idEvaluacion: response.data[i].idEvaluacion,
                                    idActivo: response.data[i].idActivo,
                                    activo: response.data[i].activo,
                                    estado: response.data[i].estado,
                                    listaVulnerabilidades: [],
                                })
                            }
                        }
                    }
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        consultarEvaluacionDetalleVulnerabilidad() {
            let me = this;

            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/consultar-evaluacion-detalle-vulnerabilidad-sgsi", {
                        params: {
                            idCliente: me.datosSession.idCliente,
                            idEvaluacion: me.$route.params.id,
                        },
                    }
                )
                .then(function (response) {
                    //me.datosEvaluacionDetalleVulnerabilidad = response.data;
                    //console.log(response.data)
                    for (const i in response.data) {
                        me.datosEvaluacionDetalleVulnerabilidad.push({
                            idEvaluacionDetalleVulnerabilidad: response.data[i].idEvaluacionDetalleVulnerabilidad,
                            idEvaluacionDetalleActivo: response.data[i].idEvaluacionDetalleActivo,
                            numero: response.data[i].numero,
                            tipo: response.data[i].tipo,
                            vulnerabilidad: response.data[i].vulnerabilidad,
                            riesgoAsociado: response.data[i].riesgoAsociado,
                            propietarioRiesgo: response.data[i].propietarioRiesgo,
                            gestorRiesgo: response.data[i].gestorRiesgo,
                            probabilidad: response.data[i].probabilidad,
                            impacto: response.data[i].impacto,
                            escala: response.data[i].escala,
                            nivelRiesgo: response.data[i].nivelRiesgo,
                            descripcionRiesgo: response.data[i].descripcionRiesgo,
                            estado: response.data[i].estado,
                            listaControles: [],
                            nivelRiesgoTexto: response.data[i].nivelRiesgo == 2 ? 'Bajo' : response.data[i].nivelRiesgo >= 3 && response.data[i].nivelRiesgo <= 4 ? 'Medio' : 'Alto',
                        });
                        if (response.data[i].idVulnerabiliadGestionRiesgo) {
                            me.datosNuevaEvaluacionRiesgo.push({
                                idVulnerabiliadGestionRiesgo: response.data[i].idVulnerabiliadGestionRiesgo,
                                idEvaluacionDetalleVulnerabilidad: response.data[i].idEvaluacionDetalleVulnerabilidad,
                                probabilidad: response.data[i].probabilidad2,
                                impacto: response.data[i].impacto2,
                                nivelRiesgo: response.data[i].nivelRiesgo2,
                                estado: response.data[i].estado2,
                                nivelRiesgoTexto: response.data[i].nivelRiesgo2 == 2 ? 'Bajo' : response.data[i].nivelRiesgo2 >= 3 && response.data[i].nivelRiesgo2 <= 4 ? 'Medio' : 'Alto',
                            })
                        } else {
                            me.datosNuevaEvaluacionRiesgo.push({
                                idVulnerabiliadGestionRiesgo: '',
                                idEvaluacionDetalleVulnerabilidad: response.data[i].idEvaluacionDetalleVulnerabilidad,
                                probabilidad: null,
                                impacto: null,
                                nivelRiesgo: '',
                                nivelRiesgoTexto: '',
                                estado: 2
                            })
                        }

                    }

                    me.consultarEvaluacionDetalleControl();

                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        consultarEvaluacionDetalleControl() {
            let me = this;

            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/consultar-evaluacion-detalle-control-sgsi", {
                        params: {
                            idCliente: me.datosSession.idCliente,
                            idEvaluacion: me.$route.params.id,
                        },
                    }
                )
                .then(function (response) {
                    me.datosEvaluacionDetalleControl = response.data;
                    for (const j in me.datosEvaluacionDetalleVulnerabilidad) {
                        for (const k in me.datosEvaluacionDetalleControl) {
                            if (me.datosEvaluacionDetalleVulnerabilidad[j].idEvaluacionDetalleVulnerabilidad == me.datosEvaluacionDetalleControl[k].idEvaluacionDetalleVulnerabilidad) {
                                me.datosEvaluacionDetalleVulnerabilidad[j].listaControles.push({
                                    idEvaluacionDetalleControl: me.datosEvaluacionDetalleControl[k].idEvaluacionDetalleControl,
                                    idEvaluacionDetalleVulnerabilidad: me.datosEvaluacionDetalleControl[k].idEvaluacionDetalleVulnerabilidad,
                                    tipoControl: me.datosEvaluacionDetalleControl[k].tipoControl,
                                    definicion: me.datosEvaluacionDetalleControl[k].definicion,
                                    fechaLimite: me.datosEvaluacionDetalleControl[k].fechaLimite,
                                    idResponsable: me.datosEvaluacionDetalleControl[k].idResponsable,
                                    recursos: me.datosEvaluacionDetalleControl[k].recursos,
                                    conseguido: me.datosEvaluacionDetalleControl[k].conseguido,
                                    estado: me.datosEvaluacionDetalleControl[k].estado
                                })
                            }
                        }

                    }
                    for (const i in me.datosAcordionActivos) {
                        for (const j in me.datosEvaluacionDetalleVulnerabilidad) {
                            if (me.datosAcordionActivos[i].idEvaluacionDetalleActivo == me.datosEvaluacionDetalleVulnerabilidad[j].idEvaluacionDetalleActivo) {
                                me.datosAcordionActivos[i].listaVulnerabilidades.push(me.datosEvaluacionDetalleVulnerabilidad[j]);
                            }
                        }
                    }

                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarPuestosTrabajo() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-puestos-trabajo", {
                        params: {
                            idCliente: me.datosSession.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.comboCargoResponsable = response.data;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        registrarEvaluacion() {
            let me = this;
            me.disabled = true;

            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-evaluacion-actvio-sgsi", {
                        datosNuevaEvaluacion: me.datosNuevaEvaluacion,
                        datosEvaluacionDetalle: me.datosAcordionActivos,
                        datosNuevaEvaluacionRiesgo: me.datosNuevaEvaluacionRiesgo,
                        idCliente: me.datosSession.idCliente
                    }, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje);

                    me.$router.push({
                        name: 'Vulnerabilidades',
                    });
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        agregarControl(row, k) {
            let me = this;
            me.datosAcordionActivos[k].listaVulnerabilidades[row.index].listaControles.push({
                idEvaluacionDetalleControl: '',
                idEvaluacionDetalleVulnerabilidad: '',
                tipoControl: null,
                definicion: '',
                fechaLimite: moment().format('YYYY-MM-DD'),
                idResponsable: null,
                recursos: '',
                conseguido: 1,
                estado: 2
            })
        },
        quitarControl(param, row, k) {
            let me = this;
            if (this.$route.params.id) {
                let listaControles = me.datosAcordionActivos[k].listaVulnerabilidades[row.index].listaControles.filter(x => x.estado == 2);
                if (listaControles[param.index].idEvaluacionDetalleControl) {
                    listaControles[param.index].estado = 1;
                } else if (!listaControles[param.index].idEvaluacionDetalleControl) {
                    listaControles[param.index].estado = 0;
                    for (let e in me.datosAcordionActivos[k].listaVulnerabilidades[row.index].listaControles) {
                        if (me.datosAcordionActivos[k].listaVulnerabilidades[row.index].listaControles[e].estado == 0) {
                            me.datosAcordionActivos[k].listaVulnerabilidades[row.index].listaControles.splice(e, 1);
                        }
                    }
                }
            } else {
                me.datosAcordionActivos[k].listaVulnerabilidades[row.index].listaControles.splice(param.index, 1);
            }
        },
        agregarVulnerabilidad(k) {
            let me = this;
            me.datosAcordionActivos[k].listaVulnerabilidades.push({
                idEvaluacionDetalleVulnerabilidad: '',
                idEvaluacionDetalleActivo: '',
                numero: '',
                tipo: '',
                vulnerabilidad: '',
                riesgoAsociado: '',
                propietarioRiesgo: '',
                gestorRiesgo: '',
                probabilidad: null,
                impacto: null,
                escala: '',
                nivelRiesgo: '',
                nivelRiesgoTexto: '',
                descripcionRiesgo: '',
                listaControles: [{
                    idEvaluacionDetalleControl: '',
                    idEvaluacionDetalleVulnerabilidad: '',
                    tipoControl: null,
                    definicion: '',
                    fechaLimite: moment().format('YYYY-MM-DD'),
                    idResponsable: null,
                    recursos: '',
                    conseguido: 1,
                    estado: 2
                }],
                estado: 2,
            });
            me.datosNuevaEvaluacionRiesgo.push({
                idVulnerabiliadGestionRiesgo: '',
                idEvaluacionDetalleVulnerabilidad: '',
                probabilidad: null,
                impacto: null,
                nivelRiesgo: '',
                nivelRiesgoTexto: '',
                estado: 2
            });
            //console.log(me.datosAcordionActivos[k].listaVulnerabilidades)
            //console.log(me.datosNuevaEvaluacionRiesgo)
        },
        quitarVulnerabilidad(row, k) {
            let me = this;
            if (me.$route.params.id) {
                let listaVulnerabilidades = me.datosAcordionActivos[k].listaVulnerabilidades.filter(x => x.estado == 2);
                if (listaVulnerabilidades[row.index].idEvaluacionDetalleVulnerabilidad) {
                    listaVulnerabilidades[row.index].estado = 1;
                    me.datosNuevaEvaluacionRiesgo[row.index].estado = 1;
                } else if (!listaVulnerabilidades[row.index].idEvaluacionDetalleVulnerabilidad) {
                    listaVulnerabilidades[row.index].estado = 0;
                    for (let e in me.datosAcordionActivos[k].listaVulnerabilidades) {
                        if (me.datosAcordionActivos[k].listaVulnerabilidades[e].estado == 0) {
                            me.datosAcordionActivos[k].listaVulnerabilidades.splice(e, 1);
                            me.datosNuevaEvaluacionRiesgo.splice(e, 1);
                        }
                    }
                }
            } else {
                me.datosAcordionActivos[k].listaVulnerabilidades.splice(row.index, 1);
                me.datosNuevaEvaluacionRiesgo.splice(row.index, 1);
            }
            /* console.log(me.datosAcordionActivos[k].listaVulnerabilidades)
            console.log(me.datosNuevaEvaluacionRiesgo) */
        },
        calcularNivelRiesgo(k, row) {
            let me = this;
            let nivelR = parseInt(me.datosAcordionActivos[k].listaVulnerabilidades[row.index].probabilidad) + parseInt(me.datosAcordionActivos[k].listaVulnerabilidades[row.index].impacto);
            if (nivelR == 2) {
                me.datosAcordionActivos[k].listaVulnerabilidades[row.index].nivelRiesgoTexto = 'Bajo';
            }
            if (nivelR >= 3 && nivelR <= 4) {
                me.datosAcordionActivos[k].listaVulnerabilidades[row.index].nivelRiesgoTexto = 'Medio';
            }
            if (nivelR >= 5) {
                me.datosAcordionActivos[k].listaVulnerabilidades[row.index].nivelRiesgoTexto = 'Alto';
            }
            me.datosAcordionActivos[k].listaVulnerabilidades[row.index].nivelRiesgo = nivelR;

        },
        calcularNivelRiesgoMatriz(row) {
            let me = this;
            let nivelR = parseInt(me.datosNuevaEvaluacionRiesgo[row.index].probabilidad) + parseInt(me.datosNuevaEvaluacionRiesgo[row.index].impacto);
            if (nivelR == 2) {
                me.datosNuevaEvaluacionRiesgo[row.index].nivelRiesgoTexto = 'Bajo';
            }
            if (nivelR >= 3 && nivelR <= 4) {
                me.datosNuevaEvaluacionRiesgo[row.index].nivelRiesgoTexto = 'Medio';
            }
            if (nivelR >= 5) {
                me.datosNuevaEvaluacionRiesgo[row.index].nivelRiesgoTexto = 'Alto';
            }
            me.datosNuevaEvaluacionRiesgo[row.index].nivelRiesgo = nivelR;

        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },
    computed: {
        computedForm() {
            return this.$refs.observer;
        }
    },
    watch: {

    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosSession.idCliente = user.uidClient;
            this.listarPuestosTrabajo();
            if (this.$route.params.arrayActivos != null && this.$route.params.id == null) {
                this.agregarRiesgos();
            } else if (this.$route.params.arrayActivos != null && this.$route.params.id != null) {
                this.consultarEvaluacionDetalleVulnerabilidad();
                this.consultarEvaluacionActivo();
            }
        }

    }

}
</script>

<style>
.disabled-success {
    background-color: #2EB85C !important;
    color: rgb(255, 255, 255) !important;
}

.disabled-warning {
    background-color: #F9B115 !important;
    color: rgb(118, 129, 146) !important;
}

.disabled-danger {
    background-color: #E55353 !important;
    color: rgb(255, 255, 255) !important;
}
</style>
